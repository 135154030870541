import React from "react";
import { arrayOf, shape } from "prop-types";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";

const Text = styled.p`
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
  line-height: calc(1ex / 0.32);
`;

const Usps = ({ items }) => {
    return (
      <>
        <Row className="align-items-center text-center justify-content-between mb-5">
          {items.map((item) => (
            <Col key={item.header} xs={3} md={4}>
              <img alt={item.description || ""} src={item.image.file.url} style={{ height: 72, width: 72 }} />
              <Text className="mb-0">{item.header}</Text>
            </Col>
          ))}
        </Row>
      </>
    );
};
Usps.defaultProps = {
  items: []
};

Usps.propTypes = {
  items: arrayOf(shape)
};

export default Usps


export const query = graphql`
  fragment uspsFragment on ContentfulLayoutUspsItemsEdge {
    node {
      title
      items {
        header
        image {
          file {
            fileName
            url
          }
        }
      }
    }
  }
`;