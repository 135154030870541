import layoutNameDescription from "./PortfolioDescription";
import layoutProjectDescription from "./ProjectsDescription";
// import layoutUspsItems from "./Usps";

const Nodes = {
  layoutNameDescription,
  layoutProjectDescription,
  // layoutUspsItems,
};

export default Nodes;