import React from "react";
import { string } from "prop-types";
import styled from "styled-components";
import { graphql } from "gatsby";
import { Row, Col, Button } from "react-bootstrap";
import { Container, Title, SubTitle } from "../styles/styles";

const StyledButton = styled(Button)`
  width: 150px;
  height: 44px;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border-color: var(--primary-color);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  font-weight: 500;
  &:hover,
  &:focus {
    transform: scale(1.05);
    border-color: var(--primary-color);
    background-color: var(--secondary-color) !important;
    color: var(--primary-color);
  }
  a {
    color: var(--primary-color);
    text-decoration: none;
    padding: 0.5rem 1rem;
  }
`;

const NormalText = styled.p`
  margin-bottom: 1.5rem;
`;

const PortfolioDescription = ({ cta, description, header, subHeader }) => {
    return (
      <Container>
        <Row>
          <Col xs={12}>
            <Title>{header}</Title>
            <SubTitle>{subHeader}</SubTitle>
          </Col>
          <Col xs={12} md={6}>
            <NormalText>{description}</NormalText>
          </Col>
          <Col xs={12}>
            <StyledButton>
              <a href="#mailgo" data-address="hi" data-domain="bpcodes.com">
                {cta}
              </a>
            </StyledButton>
          </Col>
        </Row>
      </Container>
    );
};
PortfolioDescription.defaultProps = {
  cta: "",
  description: "",
  header: "",
  subHeader: ""
};

PortfolioDescription.propTypes = {
  cta: string,
  description: string,
  header: string,
  subHeader: string
};

export default PortfolioDescription

export const nameQuery = graphql`
  fragment portfolioDescriptionFragment on ContentfulLayoutNameDescription{
  cta
  description
  header
  title
  id
  subHeader
  sys {
    contentType {
      sys {
        contentful_id
      }
    }
  }
}
`;