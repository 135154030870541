import React from "react";
import { string, shape } from "prop-types";
import Img from 'gatsby-image'
import { graphql } from "gatsby";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { Text, TextItalic } from "../styles/styles";

const StyledColText = styled(Col)`
  color: var(--white-1);
  z-index: 1;
  padding: 3rem;
`;

const StyledCol = styled(Col)`
  @media screen and (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    box-shadow: rgb(13 29 44 / 1) 0px 2px 20px;
    .description{
      margin-top: 2rem;
    }
  }
`;

const StyledRow = styled(Row)`
  @media screen and (max-width: 768px){
    min-height: 450px;
  }
`;


const ImageContainer = styled(Img)`
  max-height: 450px;
  @media screen and (max-width: 768px) {
    background-color: hsl(216 65% 20% / 1);
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    border-radius: var(--border);
    max-height: 100%;
    height: 100%;
    img {
      filter: grayscale(100%) contrast(1) brightness(80%);
      mix-blend-mode: multiply;
    }
  }
`;

const ProjectsDescription = ({ header, company, image, index, projectDescription }) => {
  const isPar = index % 2 === 0 ;
  const columnSize = {
    firstColumn: {
      span: 6,
    },
    secondColumn: {
      span: 5,
      offset: 1
    }
  }
    return (
      <StyledRow
        style={{ position: "relative" }}
        className={`align-items-center mb-5 ${isPar && "flex-md-row-reverse"}`}
      >
        <StyledColText
          xs={12}
          md={isPar ? columnSize.secondColumn : columnSize.firstColumn}
        >
          <Text className="mb-0 font-weight-bold">{header}</Text>
          <TextItalic>{company}</TextItalic>
          <div className="description">
            <ReactMarkdown
              source={projectDescription.childMarkdownRemark.rawMarkdownBody}
            />
          </div>
        </StyledColText>
        <StyledCol
          className="p-0"
          xs={12}
          md={isPar ? columnSize.firstColumn : columnSize.secondColumn}
        >
          {image && (
            <ImageContainer
              alt=""
              fluid={image.fluid}
            />
          )}
        </StyledCol>
      </StyledRow>
    );
};
ProjectsDescription.defaultProps = {
  header: "",
  company: "",
  // description: null,
  image: {},
  projectDescription: null
};

ProjectsDescription.propTypes = {
  header: string,
  company: string,
  // description: string,
  image: shape(),
  projectDescription: shape()
};

export default ProjectsDescription

export const projectQuery = graphql`
  fragment projectFragment on ContentfulLayoutProjectDescription {
    id
    company
    description
    header
    title
    sys {
      contentType {
        sys {
          contentful_id
        }
      }
    }
    image {
      fluid( maxWidth: 450, maxHeight: 570, resizingBehavior: FILL, quality: 70) {
        sizes
        aspectRatio
        src
        srcSet
        srcWebp
        srcSetWebp
      }
    }
    projectDescription {
      projectDescription
      childMarkdownRemark{
        rawMarkdownBody
        html
      }
    }
  }
`;