import React from "react";
import { arrayOf, shape } from "prop-types";
import Nodes from ".";

function Sections({ layouts }) {
  return (
    <div id="contentful-block" className="contentful-block">
      {layouts.length > 0 &&
        layouts.map((layout, index) => {
          const Children = Nodes[layout.sys.contentType.sys.contentful_id];
          return (
            (
              <Children
                index={index}
                key={layout.id}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...layout}
              />
            )
          );
        })}
    </div>
  );
}

Sections.defaultProps = {
  layouts: []
};

Sections.propTypes = {
  layouts: arrayOf(shape)
};

export default Sections;
